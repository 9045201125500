<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
              to="/"
              class="d-flex align-center"
          >
            <v-img
                :src="appLogo"
                max-height="60px"
                max-width="60px"
                alt="logo"
                contain
                class="me-3 "
            ></v-img>

            <!--            <h2 class="text-2xl font-weight-semibold">-->
            <!--              {{ appName }}-->
            <!--            </h2>-->
          </router-link>
        </v-card-title>

        <!-- title -->
        <!--        <v-card-text>-->
        <!--          <p class="text-2xl font-weight-semibold text&#45;&#45;primary mb-2">-->
        <!--            Welcome to Materio! 👋🏻-->
        <!--          </p>-->
        <!--          <p class="mb-2">-->
        <!--            Please sign-in to your account and start the adventure-->
        <!--          </p>-->
        <!--        </v-card-text>-->

        <v-card-text>
          <v-form
              ref="loginForm"
              @submit.prevent="handleFormSubmit"
          >
            <v-text-field
                v-model="email"
                outlined
                label="Email"
                placeholder="email"
                :error-messages="errorMessages.email"
                :rules="[validators.required, validators.emailValidator]"
                hide-details="auto"
                class="mb-6"
            ></v-text-field>

            <v-text-field
                v-model="password"
                outlined
                autocomplete="on"
                :type="isPasswordVisible ? 'text' : 'password'"
                label="Password"
                :error-messages="errorMessages.password"
                placeholder="Password"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                :rules="[validators.required]"
                hide-details="auto"
                class="mb-2"
                @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                  hide-details
                  label="Remember Me"
                  class="mt-0"
              >
              </v-checkbox>

              <!-- forget link -->
              <router-link
                  :to="{name:'auth-forgot-password'}"
                  class="ms-3"
              >
                Forgot Password?
              </router-link>
            </div>

            <v-btn
                block
                color="primary"
                type="submit"
                class="mt-6"
            >
              Login
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <p class="mb-0 me-2">
            New on our platform?
          </p>
          <router-link
              :to="$route.query.route? {name: 'auth-register', query: {route: $route.query.route}} : {name: 'auth-register'}">
            Create an account
          </router-link>
        </v-card-text>

        <!--        &lt;!&ndash; divider &ndash;&gt;-->
        <!--        <v-card-text class="d-flex align-center mt-2">-->
        <!--          <v-divider></v-divider>-->
        <!--          <span class="mx-5">or</span>-->
        <!--          <v-divider></v-divider>-->
        <!--        </v-card-text>-->

        <!--        &lt;!&ndash; socail links &ndash;&gt;-->
        <!--        <v-card-actions class="d-flex justify-center">-->
        <!--          <v-btn-->
        <!--              :key="googleSocialLink.icon"-->
        <!--              icon-->
        <!--              class="ms-1"-->
        <!--              @click="handleClickSignInWithGoogle"-->
        <!--          >-->
        <!--            <v-icon :color="$vuetify.theme.dark ? googleSocialLink.colorInDark:googleSocialLink.color">-->
        <!--              {{ googleSocialLink.icon }}-->
        <!--            </v-icon>-->
        <!--          </v-btn>-->
        <!--          <v-btn-->
        <!--              :key="facebookSocialLink.icon"-->
        <!--              icon-->
        <!--              class="ms-1"-->
        <!--              @click="handleClickSignInWithFacebook"-->
        <!--          >-->
        <!--            <v-icon :color="$vuetify.theme.dark ? facebookSocialLink.colorInDark:facebookSocialLink.color">-->
        <!--              {{ facebookSocialLink.icon }}-->
        <!--            </v-icon>-->
        <!--          </v-btn>-->
        <!--        </v-card-actions>-->
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
        class="auth-mask-bg"
        height="173"
        :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
        class="auth-tree"
        width="247"
        height="185"
        src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
        class="auth-tree-3"
        width="377"
        height="289"
        src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline} from '@mdi/js'
import {getCurrentInstance, ref} from '@vue/composition-api'
import themeConfig from '@themeConfig'
import {useRouter} from "@core/utils";
import {emailValidator, required} from "@core/utils/validation";
import axios from "@axios";
import store from '@/store';
import * as intercom from "@/plugins/tracking/intercom";

export default {
  setup() {
    // Template Ref
    const loginForm = ref(null)

    const vm = getCurrentInstance().proxy
    const {router} = useRouter()

    const isPasswordVisible = ref(false)

    const email = ref('')
    const password = ref('')
    const errorMessages = ref([])
    const googleSocialLink = {
      icon: mdiGoogle,
      color: '#db4437',
      colorInDark: '#db4437',
    }
    const facebookSocialLink = {
      icon: mdiFacebook,
      color: '#4267b2',
      colorInDark: '#4267b2',
    }
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    const authMe = () => {
      axios.get('/auth/me').then(response => {
        const user = response.data.data
        if (!user) {
          vm.$notify({
            group: 'deals',
            title: 'Sign in error',
            type: 'error',
            text: vm.$t("something wrong, please connect with support"),
            max: 5
          })
          return
        }
        
        const {ability: userAbility} = user

        // Set user ability
        // ? https://casl.js.org/v5/en/guide/intro#update-rules
        vm.$ability.update(userAbility)

        // Set user's ability in localStorage for Access Control
        localStorage.setItem('userAbility', JSON.stringify(userAbility))

        // We will store `userAbility` in localStorage separate from userData
        // Hence, we are just removing it from user object
        delete user.ability

        // Set user's data in localStorage for UI/Other purpose
        localStorage.setItem('userData', JSON.stringify(user))

        // init intercom
        intercom.initIntercom(vm.$intercom)

        // On success redirect to home
        router.push({name: 'products'})
      })
    }
    const handleClickSignInWithGoogle = async () => {
      const authCode = await vm.$gAuth.getAuthCode()
      axios.post('/social/google/auth-by-token', {token: authCode}).then(response => {
        const {token} = response.data
        localStorage.setItem('accessToken', token)
        return response
      }).then(() => authMe())
          .catch(error => {
            vm.$notify({
              group: 'deals',
              title: 'Sign in error',
              type: 'error',
              text: vm.$t(error.response.data.message),
              max: 5
            })
          })
    }

    const handleClickSignInWithFacebook = async () => {
      await loadFacebookSDK(document, "script", "facebook-jssdk");
      window.FB.init({
        appId: appConfig.facebookId,
        cookie: true,
        version: "v13.0"
      });
      window.FB.login(function (response) {
        if (response.authResponse) {
          axios.post('/social/facebook/auth-by-token', {token: response.authResponse.accessToken}).then(response => {
            const {token} = response.data
            localStorage.setItem('accessToken', token)
            return response
          }).then(() => authMe())
              .catch(error => {
                vm.$notify({
                  group: 'deals',
                  title: 'Sign in error',
                  type: 'error',
                  text: vm.$t(error.response.data.message),
                  max: 5
                })
              })
        } else {
          vm.$notify({
            group: 'deals',
            title: 'Sign in error',
            type: 'error',
            text: vm.$t('login with facebook error'),
            max: 5
          })
        }
      });
      return false;
    }

    const loadFacebookSDK = async (d, s, id) => {
      var js,
          fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }
    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      axios
          .post('/auth/login', {email: email.value, password: password.value})
          .then(response => {
            const {token} = response.data
            // ? Set access token in localStorage so axios interceptor can use it
            // Axios Interceptors: https://github.com/axios/axios#interceptors
            localStorage.setItem('accessToken', token)

            return response
          })
          .then(() => {
            authMe()
          })
          .catch(error => {
            vm.$notify({
              group: 'deals',
              title: 'Sign in error',
              type: 'error',
              text: vm.$t(error.response.data.message),
            })
          })
    }

    return {
      handleFormSubmit,
      handleClickSignInWithGoogle,
      handleClickSignInWithFacebook,
      isPasswordVisible,
      email,
      password,
      errorMessages,
      socialLink,
      googleSocialLink,
      facebookSocialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
